<template>
	<div class="container-fluid animatedParent animateOnce my-3">
		<!-- main content -->
		<div class="tab-content">
			<div class="tab-pane animated fadeInUpShort show active go">
				
				<!-- <div class="alert alert-danger alert-dismissible" role="alert">
					<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span>
					</button>
					<strong>通知：</strong> XXX。
				</div> -->
				
				<div class="row my-3">
					<div class="col-md-12">
						<div class="card r-0 shadow">
							<div class="card-header">
								
								<div class="row">
									<div class="col-md-9">
										<!-- 搜索 start -->
										<div class="form-inline mt-3">
											<input v-model="query" class="form-control p-2" placeholder="搜索" type="text">

											<!-- <date-picker class="ml-2" v-model="start_date" format="YYYY-MM-DD" value-type="format"
												input-class="form-control" :editable="false" :clearable="true" placeholder="有效期开始时间">
											</date-picker>
											<div class="ml-2">
												<date-picker v-model="end_date" format="YYYY-MM-DD" value-type="format"
													input-class="form-control" :editable="false" :clearable="true" placeholder="有效期结束时间">
												</date-picker>
											</div> -->
											
											<span>
												<select v-model="industry" class="form-control ml-2">
													<option value="">所有商户类型</option>
													<option value="1003">餐饮</option>
													<option value="1000">通用</option>
												</select>
											</span>

											<span>
												<select v-model="dept_id" class="form-control  ml-2">
													<option value="0">所有企业</option>
													<option v-bind:key="index" 
														:value="item.id" v-for="(item,index) in departmentlist">
														{{item.department_name}}</option>
												</select>
											</span>

											<!-- <select v-model="stock_state" class="form-control ml-2">
												<option value="">所有状态</option>
												<option v-bind:key="index"
													:value="item.value" v-for="(item,index) in stockstatelist">
													{{item.name}}</option>
											</select> -->

											<button @click="search" type="button" class="btn btn-secondary  ml-2"><i
													class="icon-search"></i>
											</button>
											
											<!-- <button @click="search" type="button" class="btn btn-xs btn-link  ml-2">
												查看过期制度
											</button> -->
											<!-- <div class="form-check ml-2">
												<label class="form-check-label">
													<input 
													v-model="expired" 
													class="form-check-input" type="checkbox"> 包括过期失效制度
												</label>
											</div> -->
										</div>
										<!-- 搜索 end -->
									</div>
									<div class="col-md-3">
										<div class="float-right m-3">
											<button @click="add" type="button" class="btn btn-secondary">添加白名单商户</button>
										</div>
									</div>
								</div>
							</div>

							<div class="table-responsive">
								<div v-if="merchantlist.length > 0">
									<table class="table table-striped table-hover r-0  mb-0">
										<thead>
											<tr class="no-b">
												<th style="width: 60px">
													<!-- <div class="custom-control custom-checkbox">
														<input v-model="check_all" @click.stop="checkAll" type="checkbox"  class="custom-control-input" id="checkedAll"><label class="custom-control-label" for="checkedAll"></label>
													</div> -->
													序号
												</th>
												<th>
													<div class="d-none d-lg-block">企业</div>
												</th>
												<th>
													<div class="d-none d-lg-block">类型</div>
												</th>
												<th>
													<div class="d-none d-lg-block">商户</div>
												</th>
												<th>
													<div class="d-none d-lg-block">门店</div>
												</th>
												
												<!-- <th>
													<div class="d-none d-lg-block">省市</div>
												</th>
												<th>
													<div class="d-none d-lg-block">地址</div>
												</th> -->
												
												<th>
													<div class="d-none d-lg-block">状态</div>
												</th>
												<th>
													<div class="d-none d-lg-block">添加时间</div>
												</th>
												<th></th>
											</tr>
										</thead>

										<tbody>
											<tr v-bind:key="index" v-for="(item,index) in merchantlist"
												:class="{'blue lighten-5':selectedIndex == index}">
												<td>
													{{index+1}}
													<!-- <label class="custom-control custom-checkbox">
														<input v-model="checkedIds" :value="item.id" :id="index" :disabled="item.enabled == -1" class="custom-control-input" type="checkbox"><label class="custom-control-label" :for="index"></label>
													</label> -->
												</td>
												<td>
													<div class="text-ellipsis" style="width: 180px;">
													<!-- {{item.dept_id}} -->
													<template v-if="deptmap.hasOwnProperty(item.dept_id)">
														{{deptmap[item.dept_id].department_name}}
													</template>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<!-- {{mccMap[item.industry]}} -->
														{{item.industry==1003?'餐饮':'通用'}}
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
													{{item.company_name}}
													<br>
													<span class="text-muted">{{item.pid}}</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
													<span class="font-weight-bold">{{item.name}}</span>
													<br>
													<span class="text-muted">{{item.shop_id}}</span>
													</div>
												</td>

												<!-- <td>
													<div class="d-none d-lg-block"><span
															class="r-3">{{item.state}}{{item.city}}</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block text-ellipsis" style="width: 160px;">
													{{item.street}} 
													</div>
												</td> -->
												
												<td>
													<div class="d-none d-lg-block">
													<span :class="{'text-success':item.enabled==1,'text-danger':item.enabled==0}">{{item.enabled==1?'启用':'停用'}}</span>
													</div>
												</td>
												
												<td>
													<div class="d-none d-lg-block">{{item.create_date}}</div>
												</td>
												
												<td class="form-inline pt-4">
													<!-- <button @click.stop="view(index)" type="button"
														class="btn btn-outline-primary btn-xs ml-2"> 详情 </button> -->
													<!-- <button v-if="item.enabled==0"
														@click.stop="launch(index,1)" type="button"
														class="btn btn-outline-primary btn-xs ml-1"> 投放 </button>	
													<button v-if="item.enabled==1" 
														@click.stop="launch(index,0)" type="button"
														class="btn btn-outline-primary btn-xs ml-1"> 停止投放 </button>	 -->	
													
													<button
														@click.stop="edit(index)" type="button"
														class="btn btn-outline-primary btn-xs ml-1"> 编辑 </button>
														
													<!-- 	<button
															@click.stop="updateInfoReady(index)" type="button"
															class="btn btn-outline-danger btn-xs ml-1"> 删除 </button>
													 -->
													<!-- <div class="dropdown">
													  <button type="button" class="btn btn-link ml-2"  role="button" id="" data-toggle="dropdown" aria-expanded="false">
													   <i class="icon-more_horiz"></i>	
													  </button>
													  <div class="dropdown-menu" aria-labelledby="">
													    <span @click.stop="launch(index,1)" class="dropdown-item" >投放</span>
													    <span @click.stop="launch(index,0)" class="dropdown-item" >停止投放</span>
													  </div>
													</div> -->
													
												</td>
											</tr>
										</tbody>
										
									</table>
								</div>
								<div v-if="merchantlist.length == 0">
									<div class="card-body text-lg-center m-5 height-300">
										<h5 class="mb-3">没有商户</h5>
										<!-- <button @click="add" type="button" class="btn btn-lg btn-secondary">
											创建制度
										</button> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row" v-if="merchantlist.length > 0">
					<div class="col-sm-12 col-md-5">
						<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
							显示 {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
						</div>
					</div>
					<div class="col-sm-12 col-md-7">

						<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>

					</div>
				</div>

			</div>

		</div>
		<!-- //main content -->

		<!-- modal -->
		
		<!--  添加/编辑 modal -->
		<div class="modal fade" id="cModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">添加白名单</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
		
						<div class="m-3">
							
							<div class="form-group">
								<label for="" class="s-12 font-weight-bold">归属企业</label>
								<select v-model="merchant.dept_id" class="form-control">
									<option value="">选择企业</option>
									<template v-for="(item,index) in departmentlist">
									<option v-bind:key="index"
										:value="item.id"  v-if="item.enabled==1">
										{{item.department_name}}</option>
									</template>	
								</select>
								<div class="s-12">
								</div>	
							</div>
							
							<div class="form-group">
								<label for="" class="s-12 font-weight-bold">商户类型</label>
								<select v-model="merchant.industry" class="form-control">
									<option value="1003">餐饮</option>
									<option value="1000">通用</option>
								</select>
								<div class="s-12">
								</div>	
							</div>
							
							<div class="form-group">
								<label for="" class="s-12 font-weight-bold">商户名称</label>
								<input v-model="merchant.company_name" type="text" class="form-control"
									placeholder="输入商户名称">
								<div class="s-12">
								</div>	
							</div>
							
							<div class="form-group">
								<label for="" class="s-12 font-weight-bold">商户支付宝PID</label>
								<input v-model="merchant.pid" type="number" class="form-control" 
								placeholder="输入商户支付宝PID">
								<div class="s-12">
								</div>	
							</div>
							
							<div class="form-group">
								<label for="" class="s-12 font-weight-bold">门店名称</label>
								<input v-model="merchant.name" type="text" class="form-control"
									placeholder="输入门店名称">
								<div class="s-12">
								</div>	
							</div>
		
							<div class="form-group">
								<label for="" class="s-12 font-weight-bold">蚂蚁门店ID</label>
								<input v-model="merchant.shop_id" type="number" class="form-control" 
								placeholder="输入蚂蚁门店ID">
								<div class="s-12">
								</div>	
							</div>
		
						</div>
		
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button v-if="selectedIndex != -1" @click="removeReady(selectedIndex)" type="button" class="btn btn-danger"> 删除 </button>
						<button
						@click.stop="save" :disabled="disabled" 
						type="button" class="btn btn-info"> 保存
						</button>
						
						
					</div>
				</div>
			</div>
		</div>
		<!-- //添加/编辑 modal -->

	</div>
</template>

<script>
	

	import VPagination from '../components/my-page.vue'
	// import DatePicker from 'vue2-datepicker';
	// import 'vue2-datepicker/index.css';
	// import 'vue2-datepicker/locale/zh-cn';
	
	export default {
		data() {
			return {
				title: ' 制度列表',

				token: '',
				outlet_id: 0,
				outlet_name: '',
				user_name: '',
				outlet_level: 0,

				setting: {},
				outletlist: [],

				query: '',

				current_page: 1,
				page_size: 50,
				count: 0,
				total_page: 0,
				show_from: 0,
				show_to: 0,

				total: 0, // 记录总条数
				display: 10, // 每页显示条数
				current: 1, // 当前的页数

				selectedIndex: -1,
				merchant: {}, //选中的对象
				count_type: 1,

				//////
				start_date: '',
				end_date: '',
				
				disabled: false,
				
				
				merchantlist: [],
				
				enabled: -1,
				enabled_merchants: 0,//投放数
				expired: false,
				
				////
				departmentlist: [],
				dept_id: '',
				deptmap: {}, //key为 dept id, value为 dept
				mccMap: {
					'MEAL':'餐饮',
					'DEFAULT': '通用'
				},
				submccMap: {
					'REACH_SHOP':'到店',
					'TAKE_AWAY':'外卖',
					'DEFAULT': '通用'
				},
				ppMap: {
					'COMBINATION': '超额部分由个人支付',
					'PERSONAL': '整单全部由个人支付'
				},
				
				industry: '',
			}

		},
		components: {
			VPagination,
			// DatePicker
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.dept_id = lsLoginData.dept_id;
		},

		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//先取预载数据，再取业务数据
			self.init(() => {
				self.initData();
			})
		},
		methods: {

			init(callback) {
    		let self = this;
    	
    		//
    		this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
    				params: {
    					token: self.token,
    					// dept_id: self.dept_id,
    					// query: self.query,
    					// current_page: self.current_page,
    					// page_size: self.page_size,
    				}
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    				if (data.code == 200) {
    					self.departmentlist = data.data.departments;
						
						for (let i = 0; i < self.departmentlist.length; i++) {
							let dept = self.departmentlist[i];
							self.deptmap[dept.id] = dept;
						}
						console.log('--- dept map ->', self.deptmap);
						
						if (typeof callback == 'function') {
						    callback()
						}
    				} 
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    		//END
    	
    	},

			initData() {
				let self = this;
				
				self.loader = self.$loading.show();

				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getMerchantList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							// start_date: self.start_date,//有效期
							// end_date: self.end_date,
							industry: self.industry,
							query: self.query,
							current_page: self.current_page,
							page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						self.loader.hide();
						
						console.log(data.data);
						if (data.code == 200) {

							self.merchantlist = data.data.merchant_list;
							
							self.start_date = data.data.start_date;
							self.end_date = data.data.end_date;

							self.current_page = data.data.page_num;
							self.page_size = data.data.page_size;
							self.count = data.data.total;
							self.total_page = Math.ceil(self.count / self.page_size);
							console.log('--- self.total_page ->', self.total_page)

							self.show_from = self.page_size * (self.current_page - 1) + 1;
							self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
								.current_page : self.count;

							self.total = self.count; // 记录总条数
							self.display = self.page_size; // 每页显示条数
							self.current = self.current_page; // 当前的页数

							// self.merchantlist = []; //重置
							// for (let merchant of data.data.list) {
							// 	// merchant.typeDesc = __STOCKTYPE[merchant.stock_type];
							// 	// merchant.stateDesc = merchant.stock_state?__STOCKSTATE[merchant.stock_state]:""
								
							// 	self.merchantlist.push(merchant);
							// }
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END

			},

			pagechange: function(currentPage) {
				console.log(currentPage);
				// ajax请求, 向后台发送 currentPage, 来获取对应的数据
				this.current_page = currentPage;
				this.initData();
			},

			search() {
				let self = this;

				self.initData();
			},
			
			checkExpired(){
				console.log('--- expired->',this.expired)
				this.expired = this.expired?1:0;
			},
			
			add(){
				let self = this;
				
				self.selectedIndex = -1;
				self.merchant = {
					dept_id: '',
					industry: '1003',
					name: '',
					pid: '',
					shop_id: '',
					company_name: '',
				}
				
				$('#cModal').modal('show');
			},
			
			edit(index){
				console.log(index);
				let self = this;
							
				self.selectedIndex = index;
				self.merchant = Object.assign({}, self.merchantlist[index]);
				console.log(index, self.merchant);
				
				// let query = {
				//    id: merchant.id,
				//    stock_id: merchant.stock_id,
				//    nonce_str: merchant.nonce_str,
				// }
				
				// self.$router.push({
				//     name: 'editmchmerchant',
				//     // params: params,
				//     query: query,
				//     meta: {
				//         title: '编辑商品',
				//         isShow: true,
				//         readonly: true
				//     }
				// }) //get 提交
				
				$('#cModal').modal('show');
			},
			
			
			refresh(index) {
				console.log(index);
				let self = this;
			
				let merchant = self.merchantlist[index];
				console.log(index, merchant);
			
				self.selectedIndex = index;
				
				// 组装数据
				let postData = {
					token: self.token,
					outlet_id: self.outlet_id,
					id: merchant.id,
					stock_id: merchant.stock_id,
					nonce_str: merchant.nonce_str,
				}
				
				self.axios.get(self.GLOBAL.baseURI + 'refreshInstitution', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
				
						if (data.code == 200) {
							
							self.$toasted.success('刷新成功', {
								position: 'top-center',
							}).goAway(2000)
							
							let _merchant = data.data.merchant;
							_merchant.typeDesc = __STOCKTYPE[_merchant.stock_type];
							_merchant.stateDesc = __STOCKSTATE[_merchant.stock_state];
							
							self.$set(self.merchantlist, index, _merchant);
							
							// if (typeof callback == 'function') {
							// 	callback()
							// }
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else {
							// alert(data.message)
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
				
			},
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.init(() => {
					self.initData();
					self.employee_id = 0;
				})

			},

			save() {
				let self = this;

				console.log('--- self.merchant ->', self.merchant)
				
				if (!self.merchant.dept_id) {
					self.$toasted.error('请选择归属企业', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.merchant.industry) {
					self.$toasted.error('请选择商户类型', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.merchant.name) {
					self.$toasted.error('请输入商户名称', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.merchant.pid) {
					self.$toasted.error('请输入商户支付宝PID', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.merchant.shop_id) {
					self.$toasted.error('请输入商户支付宝蚂蚁门店ID', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				self.disabled = true;

				// 组装数据
				let postData = self.merchant;
				postData['token'] = self.token;
				
				let postData2 = new FormData();
				for(var key in postData){
					console.log(key+':'+postData[key]);
					postData2.append(key,postData[key]);
				}
				
				let url = self.selectedIndex == -1 ?'addMerchant':'updateMerchant';

				//调用接口
				self.axios.post(self.GLOBAL.baseURI + url, 
						postData2
					)
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;

						if (data.code == 200) {
							
							if(data.data.result_code == 'success'){
								self.$toasted.success( '保存成功', {
									position: 'top-center',
									duration: '2000',
								})
								
								self.initData();
								
								$('#cModal').modal('hide');
							}
							else{
								self.$toasted.error( data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else {
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},
			
			removeReady(){
				
				let self = this;
				
				console.log(self.selectedIndex, self.merchant);
				
				self.$confirm({
					title: '警告',
					message: '删除前，必须BD解决白名单，确定删除？',
					button: {
						no: '取消',
						yes: '确定',
					},
					callback:(res)=>{
						console.log(res)
						if(res){
							self.remove();
						}
						
					}
				});
			},
			
			remove() {
				let self = this;
				console.log(self.merchant);
				// return;
			
				self.disabled = true;
			
				// 组装数据
				let postData = {
					token: self.token,
					dept_id: self.merchant.dept_id,
					id: self.merchant.id,
					nonce_str: self.merchant.nonce_str || '',
				}
			
				this.axios.get(this.GLOBAL.baseURI + 'delMerchant', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
			
						self.disabled = false;
			
						if (data.code == 200) {
			
							if(data.data.result_code == 'success'){
								self.$toasted.success( '删除成功', {
									position: 'top-center',
									duration: '2000',
								})
								
								self.initData();
								
								$('#cModal').modal('hide');
							}
							else{
								self.$toasted.error( data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
						} 
						else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} 
						else {
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
			},
			
		},
	}
</script>

<style>
	.mx-icon-calendar,
	.mx-icon-clear {
		right: 28px !important;
	}
	.text-ellipsis{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
